import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.defaults.withCredentials = false;

/**
 *
 * @param {string} restCommand 'GET' || 'POST' || 'PUT' || 'DELETE'
 * @param {string} url URL to make request to
 * @param {Object} headers Request headers object
 * @param {Object} body Request body object
 *
 * @returns {Any} Returns results from A.P.I request
 */
const request = (restCommand, url, headers, body, axiosReference) => {
    switch (restCommand) {
        case 'GET':
            return axiosReference.get(url, headers);
        case 'POST':
            return axiosReference.post(url, body, headers);
        case 'PUT':
            return axiosReference.put(url, body, headers);
        case 'DELETE':
            return axiosReference.delete(url, { data: body, headers });
        default:
            return null;
    }
};

/**
 *
 * @param {Object} axiosConfiguration Axios request configuration.
 * @param {Object} body Request body object.
 * @param {Object} headers Request headers object.
 * @param {Function} onSuccessCallback Callback function to handle on success request.
 * @param {Function} onFailureCallback Callback function to handle on fa request.
 *
 * @returns {Object} Returns an object which is the result from the request if there isn't any errors occurred.
 */
const makeRequest = (
    config,
    body,
    headers,
    onSuccessCallback = (data) => Promise.resolve(data), // default
    onFailureCallback = (err) => Promise.resolve(err), // default
) => () => {
    // Each CFG parameter should be optional -- so override default CFG with whatever params the user passes in
    const {
        rest_command, route, customErrorHandler,
    } = config;

    const axiosCall = async () => {
        const defaultErrorHandler = async (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
        };

        try {
            const { data } = await request(rest_command, route, headers, body, axiosInstance);

            return onSuccessCallback(data);
        }
        catch (err) {
            /*
             * Allow to override this .catch() block with a custom .catch() block by setting
             * config.use_shared_error_handler to false.
             */
            if (customErrorHandler) {
                return onFailureCallback(err).catch(() => defaultErrorHandler(err));
            }

            return defaultErrorHandler(err);
        }
    };

    return axiosCall();
};

export default makeRequest;
