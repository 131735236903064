/**
 * @fileoverview
 *      ModelActions handles creating and updating the user input of the model parameters and update the result
 *      returned from backend server
 * @module ModelActions
 * @category Redux
 * @subcategory Actions
 */

import mixpanel from 'mixpanel-browser';
import { UPDATE_X, UPDATE_MODEL, UPDATE_RESULT } from './Type';
import axiosRequestWrapper from './axiosRequestWrappers';
import {
    GENDER, PLANNED, ICU, CARDIOVASCULAR, THORAX, OTHER_SURGERY, OBESITY, DIABETES, CHF,
    BICARBONATE, SODIUM, CHLORIDE, BLOOD_GLUCOSE, POTASSIUM, WHITE_BLOOD_CELL, FIRST_SCR, AGE,
    CONCOMITANT, MODEL_SELECTION,
} from '../components/ModelConstants';

/**
 * Change parameters in X
 *
 * @param {Array} X new X to replace the old one
 *
 * @returns {Void}
 */
export const updateInput = (X) => (dispatch) => {
    dispatch({
        type: UPDATE_X,
        payload: X,
    });
};

/**
 * Change selected model
 *
 * @param {String} model new model selected
 *
 * @returns {Void}
 */
export const updateModel = (model) => (dispatch) => {
    dispatch({
        type: UPDATE_MODEL,
        payload: model,
    });
};

/**
 * Predict with given inputs
 * @param {Array} X
 * @param {Number} modelSelection
 *
 * @returns {Function} axios wrapper that send the request
 */
export const predict = (X, modelSelection) => (dispatch) => {
    const body = {
        X,
        model: MODEL_SELECTION.reducer_options[modelSelection],
    };

    const axiosConfiguration = {
        rest_command: 'POST',
        route: '/aki_prediction',
        config: {
            custom_error_handler: true,
        },
    };

    const onSuccessCallback = (results) => new Promise((resolve) => {
        const predictionScore = results.results * 100;

        dispatch({
            type: UPDATE_RESULT,
            payload: predictionScore,
        });

        mixpanel.track('AKI Predictor', {
            action: 'Result Recieved',
            GENDER: X[GENDER.X_pos],
            AGE: X[AGE.X_pos],
            PLANNED: X[PLANNED.X_pos],
            CARDIOVASCULAR: X[CARDIOVASCULAR.X_pos],
            THORAX: X[THORAX.X_pos],
            OTHER_SURGERY: X[OTHER_SURGERY.X_pos],
            ICU: X[ICU.X_pos],
            BICARBONATE: X[BICARBONATE.X_pos],
            SODIUM: X[SODIUM.X_pos],
            CHLORIDE: X[CHLORIDE.X_pos],
            BLOOD_GLUCOSE: X[BLOOD_GLUCOSE.X_pos],
            POTASSIUM: X[POTASSIUM.X_pos],
            WHITE_BLOOD_CELL: X[WHITE_BLOOD_CELL.X_pos],
            OBESITY: X[OBESITY.X_pos],
            DIABETES: X[DIABETES.X_pos],
            CHF: X[CHF.X_pos],
            FIRST_SCR: X[FIRST_SCR.X_pos],
            CONCOMITANT: X[CONCOMITANT.X_pos],
            model: MODEL_SELECTION.reducer_options[modelSelection],
            result: predictionScore,
        });

        resolve();
    });

    const onFailureCallback = (err) => new Promise((resolve) => {
        mixpanel.track('AKI Predictor', {
            action: 'Error Received',
            GENDER: X[GENDER.X_pos],
            AGE: X[AGE.X_pos],
            PLANNED: X[PLANNED.X_pos],
            CARDIOVASCULAR: X[CARDIOVASCULAR.X_pos],
            THORAX: X[THORAX.X_pos],
            OTHER_SURGERY: X[OTHER_SURGERY.X_pos],
            ICU: X[ICU.X_pos],
            BICARBONATE: X[BICARBONATE.X_pos],
            SODIUM: X[SODIUM.X_pos],
            CHLORIDE: X[CHLORIDE.X_pos],
            BLOOD_GLUCOSE: X[BLOOD_GLUCOSE.X_pos],
            POTASSIUM: X[POTASSIUM.X_pos],
            WHITE_BLOOD_CELL: X[WHITE_BLOOD_CELL.X_pos],
            OBESITY: X[OBESITY.X_pos],
            DIABETES: X[DIABETES.X_pos],
            CHF: X[CHF.X_pos],
            FIRST_SCR: X[FIRST_SCR.X_pos],
            CONCOMITANT: X[CONCOMITANT.X_pos],
            model: MODEL_SELECTION.reducer_options[modelSelection],
            error_message: err,
        });

        resolve();
    });

    return axiosRequestWrapper(axiosConfiguration, body, {}, onSuccessCallback, onFailureCallback)(dispatch);
};
