/**
 * @fileoverview RootReducer handles initiating Redux store by combining all of the reducers into a single root reducer.
 *
 * @see https://redux.js.org/tutorials/fundamentals/part-1-overview
 *
 * @category Redux
 * @module RootReducer
 * @subcategory Reducer
 */
 import { combineReducers } from 'redux';

 import ModelReducer from './ModelReducer'
 
 /**
  * A reducer that invokes every reducer inside the reducers object and constructs a state object with the same shape.
  * <br/>
  * @see https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers
  *
  * @function AppReducer
  *
  * @param {Reducer} ModelReducer TODO
  *
  * @returns {Function} A reducer that invokes every reducer inside the reducers object, and constructs a state object
  *      with the same shape.
  */
 const AppReducer = combineReducers({
     Model: ModelReducer
 });

 /**
 * Creates a Redux store that holds the state tree, or root reducer. In order to make a change to the tree, you must
 * call `dispatch()` in an action.
 *
 * @function RootReducer
 *
 * @param {Object} state Current Redux Store's state
 * @param {Object} action Object that describes something that happened in the application.
 *
 * @returns {Function} A reducing function that returns the next state tree, given the current state tree and an action
 *      to handle.
 */
const RootReducer = (state, action) => {
    return AppReducer(state, action);
}
 
 export default RootReducer;
 