import React, { useCallback } from 'react';
import {
    Col, Row, Form, Alert,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { chunk, cloneDeep, isNull } from 'lodash';
import {
    GENDER, PLANNED, ICU, CARDIOVASCULAR, THORAX, OTHER_SURGERY, OBESITY, DIABETES, CHF, BICARBONATE, SODIUM, CHLORIDE,
    BLOOD_GLUCOSE, POTASSIUM, WHITE_BLOOD_CELL, FIRST_SCR, AGE, CONCOMITANT,
} from './ModelConstants';
import { updateInput, predict } from '../actions/ModelActions';

import './ModelInput.css';
import ButtonGroup from './ButtonGroup';
import References from './References';

/**
 * @class ModelInput handles rendering all required inputs for AKI prediction.
 *
 * @param {Array} X The input array filled by user. It will be send to backend server after click submit.
 * @param {Number} model_selection The index of the model selected by user.
 * @param {Function} updateInput redux action to modify array X.
 *
 * @returns the ModelInput component
 */
const ModelInput = () => {
    const X = useSelector((state) => state.Model.X);
    const modelSelection = useSelector((state) => state.Model.model_selection);
    const result = useSelector((state) => state.Model.result);
    const dispatch = useDispatch();

    /**
     * @function
     *
     * Updates the X array when user interacts with any of the inputs.
     *
     * @param {Object} e the changing event
     *
     */
    const handleOnChangeX = useCallback((e) => {
        const { name, value, index } = e.target;
        const tempX = cloneDeep(X);

        switch (name) {
            case GENDER.name:
                tempX[GENDER.X_pos] = parseInt(index, 10);
                break;
            case PLANNED.name:
                tempX[PLANNED.X_pos] = parseInt(index, 10);
                break;
            case ICU.name:
                tempX[ICU.X_pos] = parseInt(index, 10);
                break;
            case CARDIOVASCULAR.name:
                tempX[CARDIOVASCULAR.X_pos] = parseInt(index, 10);
                break;
            case THORAX.name:
                tempX[THORAX.X_pos] = parseInt(index, 10);
                break;
            case OTHER_SURGERY.name:
                tempX[OTHER_SURGERY.X_pos] = parseInt(index, 10);
                break;
            case OBESITY.name:
                tempX[OBESITY.X_pos] = parseInt(index, 10);
                break;
            case DIABETES.name:
                tempX[DIABETES.X_pos] = parseInt(index, 10);
                break;
            case CHF.name:
                tempX[CHF.X_pos] = parseInt(index, 10);
                break;
            case BICARBONATE.name:
                tempX[BICARBONATE.X_pos] = parseFloat(value);
                break;
            case SODIUM.name:
                tempX[SODIUM.X_pos] = parseFloat(value);
                break;
            case CHLORIDE.name:
                tempX[CHLORIDE.X_pos] = parseFloat(value);
                break;
            case BLOOD_GLUCOSE.name:
                tempX[BLOOD_GLUCOSE.X_pos] = parseFloat(value);
                break;
            case POTASSIUM.name:
                tempX[POTASSIUM.X_pos] = parseFloat(value);
                break;
            case WHITE_BLOOD_CELL.name:
                tempX[WHITE_BLOOD_CELL.X_pos] = parseFloat(value);
                break;
            case FIRST_SCR.name:
                tempX[FIRST_SCR.X_pos] = parseFloat(value);
                break;
            case AGE.name:
                tempX[AGE.X_pos] = parseFloat(value);
                break;
            case CONCOMITANT.name:
                tempX[CONCOMITANT.X_pos] = parseInt(index, 10);
                break;
            default:
                return;
        }

        dispatch(updateInput(tempX));
    }, [X, dispatch]);

    /**
     * @function
     *
     * Send REST request to backend when click submit button.
     */
    const handleButtonClick = useCallback(() => {
        dispatch(predict(X, modelSelection));
    }, [X, dispatch, modelSelection]);

    const isInvalidRange = useCallback((variable, value) => {
        const valueCheck = !value;
        const minValueCheck = 'min' in variable && value < variable.min;
        const maxValueCheck = 'max' in variable && value > variable.max;

        return valueCheck || minValueCheck || maxValueCheck;
    }, []);

    /**
     * @function
     *
     * Validate X to enable or disable submit button.
     *
     * @param {Array} xRef the inputs that needs to be validated
     */
    const disableButton = useCallback((xRef) => (
        isInvalidRange(AGE, xRef[AGE.X_pos]) ||
            isInvalidRange(BICARBONATE, xRef[BICARBONATE.X_pos]) ||
            isInvalidRange(SODIUM, xRef[SODIUM.X_pos]) ||
            isInvalidRange(CHLORIDE, xRef[CHLORIDE.X_pos]) ||
            isInvalidRange(BLOOD_GLUCOSE, xRef[BLOOD_GLUCOSE.X_pos]) ||
            isInvalidRange(POTASSIUM, xRef[POTASSIUM.X_pos]) ||
            isInvalidRange(WHITE_BLOOD_CELL, xRef[WHITE_BLOOD_CELL.X_pos]) ||
            isInvalidRange(FIRST_SCR, xRef[FIRST_SCR.X_pos]) ||
            xRef.some((x) => x === -1)
    ), [isInvalidRange]);

    const generateInputs = useCallback((header, inputs) => {
        const columns = [];
        const chunks = chunk(inputs, 4);
        chunks.forEach((arr) => {
            let columnInput;

            arr.forEach((input) => {
                const { type } = input;
                const {
                    label, name, defaultValue, X_pos: xPos, min, max, options, span,
                } = input;
                switch (type) {
                    case 'number':
                        columnInput = (
                            <Form.Control
                                className="modelInput-numInput-form"
                                defaultValue={defaultValue}
                                isInvalid={(!Number.isNaN(X[xPos]) && X[xPos] !== '') &&
                            isInvalidRange(input, X[xPos])}
                                name={name}
                                onChange={handleOnChangeX}
                                placeholder={`(${min} - ${max})`}
                                type="number"
                            />
                        );
                        break;
                    case 'option':
                        columnInput = (
                            <ButtonGroup
                                name={name}
                                onClick={handleOnChangeX}
                                options={options}
                                optionsToDisplay={options}
                                selectedIndex={X[xPos]}
                            />
                        );
                        break;
                    default:
                        return null;
                }

                if (!isNull(columnInput)) {
                    columns.push(
                        <Col
                            key={name}
                            className="modelInput-input-col"
                            xs={span}
                        >
                            <span className="modelInput-label">
                                {label}
                            </span>
                            {columnInput}
                        </Col>,
                    );
                }

                return null;
            });
        });

        return (
            <Row className="modelInput-section-row">
                <Col>
                    <Row className="modelInput-sectionHeader-row">
                        <span className="modelInput-sectionHeader-span">{header}</span>
                    </Row>
                    <Row className="modelInputColumns-row">
                        {columns}
                    </Row>
                </Col>
            </Row>
        );
    }, [X, handleOnChangeX, isInvalidRange]);

    return (
        <Col>
            {generateInputs('General Admission Information', [
                AGE,
                GENDER,
                PLANNED,
                ICU,
            ])}
            {generateInputs('Planned Services during Hospitalization', [
                CARDIOVASCULAR,
                THORAX,
                OTHER_SURGERY,
                CONCOMITANT,
            ])}
            {generateInputs('Past Medical History', [
                OBESITY,
                DIABETES,
                CHF,
            ])}
            {generateInputs('Lab Results Upon Admission', [
                BICARBONATE,
                SODIUM,
                CHLORIDE,
                BLOOD_GLUCOSE,
                POTASSIUM,
                FIRST_SCR,
                WHITE_BLOOD_CELL,
            ])}
            <hr className="modelInput-hr" />
            <Row className="mb-2">
                <Col
                    className="modelInputPredict-button-col pe-0"
                    xs={3}
                >
                    <button
                        className="modelInputPredict-button outlinedButton w-100 h-100"
                        disabled={disableButton(X)}
                        onClick={handleButtonClick}
                        type="button"
                    >
                        Predict Risk
                    </button>
                </Col>
                <Col
                    className="pw-1"
                    xs={9}
                >
                    <Alert
                        className="p-2 mb-0"
                        variant="dark"
                    >
                        <div className="modelInput-result-span">
                            {result >= 0
                                ? `Risk of developing AKI: ${result.toFixed(2)}%`
                                : 'Risk of developing AKI: ---%'}
                        </div>
                    </Alert>
                </Col>
            </Row>
            <Col
                className="mt-2"
                xs={12}
            >
                <References />
            </Col>
        </Col>
    );
};

export default ModelInput;
