import { UPDATE_X, UPDATE_MODEL, UPDATE_RESULT } from '../actions/Type';
import {
    GENDER, PLANNED, ICU, CARDIOVASCULAR, THORAX, OTHER_SURGERY, OBESITY, DIABETES, CHF,
    BICARBONATE, SODIUM, CHLORIDE, BLOOD_GLUCOSE, POTASSIUM, WHITE_BLOOD_CELL, FIRST_SCR, AGE,
    CONCOMITANT, MODEL_SELECTION,
} from '../components/ModelConstants';

const initialState = {
    X: [
        GENDER.defaultValue,
        AGE.defaultValue,
        PLANNED.defaultValue,
        CARDIOVASCULAR.defaultValue,
        THORAX.defaultValue,
        OTHER_SURGERY.defaultValue,
        ICU.defaultValue,
        BICARBONATE.defaultValue,
        SODIUM.defaultValue,
        CHLORIDE.defaultValue,
        BLOOD_GLUCOSE.defaultValue,
        POTASSIUM.defaultValue,
        WHITE_BLOOD_CELL.defaultValue,
        OBESITY.defaultValue,
        DIABETES.defaultValue,
        CHF.defaultValue,
        FIRST_SCR.defaultValue,
        CONCOMITANT.defaultValue,
    ],
    model_selection: MODEL_SELECTION.defaultValue,
    result: -1,
};

/**
 * ModelReducer handles any dispatched actions that update the Store's "model" state object.
 *
 * @param {Object} state Current Redux Store's state
 * @param {Object} action Object that describes something that happened in the application.
 *
 * @returns {Object} Returns the updated "analytics" tree object.
 */
export default function ModelReducer(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_X:
            return {
                ...state,
                X: action.payload,
                result: -1,
            };
        case UPDATE_MODEL:
            return {
                ...state,
                model_selection: action.payload,
                result: -1,
            };
        case UPDATE_RESULT:
            return {
                ...state,
                result: action.payload,
            };
        default: {
            return state;
        }
    }
}
