/**
 * @fileoverview
 *      This file renders the button group
 */
import React from 'react';
import { Col, ButtonGroup as BootstrapButtonGroup } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import './ButtonGroup.css';

/**
 * @param {boolean} disabled - Indicate whether the component should be disabled
 * @param {string} name - Override onClick's event.target.name return value if onClick requires it
 * @param {Function} onClick - A function to call when an option is clicked
 * @param {Array} options - A list of options
 * @param {Array} optionsToDisplay - A list of options (localization)
 * @param {number} selectedIndex - the index of the selected option
 */
const ButtonGroup = (props) => {
    const {
        disabled, name, onClick, options, optionsToDisplay, selectedIndex,
    } = props;

    const handleClick = (e) => {
        const index = e.target.name;
        onClick({
            target: {
                value: options[index],
                index,
                name,
            },
        });
    };

    const display = isEmpty(optionsToDisplay) ? options : optionsToDisplay;

    return (
        <Col className="d-flex justify-content-left align-items-center p-0">
            <BootstrapButtonGroup className="buttonGroup">
                {isEmpty(options)
                    ? (
                        <button
                            className="buttonGroup-button-placeholder primaryText"
                            disabled={disabled}
                            type="button"
                        >
                            ---
                        </button>
                    )
                    : options.map((option, index) => (
                        <button
                            key={option + name}
                            className={`primaryText${index === selectedIndex ? ' buttonGroup-button-selected' : ''}`}
                            disabled={disabled}
                            name={index}
                            onClick={handleClick}
                            type="button"
                        >
                            {display[index]}
                        </button>
                    ))}
            </BootstrapButtonGroup>
        </Col>
    );
};

ButtonGroup.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    optionsToDisplay: PropTypes.array,
    selectedIndex: PropTypes.number,
};

ButtonGroup.defaultProps = {
    optionsToDisplay: [],
    disabled: false,
    selectedIndex: -1,
    name: '',
};

export default ButtonGroup;
