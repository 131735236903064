import { isUndefined } from 'lodash';
import { DEFAULT_CONFIG, SERVER_IP } from './AxiosConstants';
import makeRequest from './AxiosRequest';

const axiosRequestWrapper = (
    axiosConfiguration,
    body,
    headers,
    onSuccessCallback,
    onFailureCallback,
) => (dispatch) => {
    // eslint-disable-next-line no-console
    console.assert(
        !isUndefined(typeof axiosConfiguration.server),
        `Server is not specified for route ${axiosConfiguration.route}`,
    );

    const {
        rest_command, route, config,
    } = axiosConfiguration;

    const requestConfig = {
        ...DEFAULT_CONFIG,
        ...config,
        rest_command,
        route: SERVER_IP + route,
    };

    return makeRequest(requestConfig, body, headers, onSuccessCallback, onFailureCallback)(dispatch);
};

export default axiosRequestWrapper;
