import React from 'react';
import { Provider } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import Home from './components/Home';

import Store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';

mixpanel.init('a3521e1d5aecefa072fe430df6ada780');

const App = () => (
    <Provider store={Store}>
        <div className="App">
            <Home />
        </div>
    </Provider>
);

export default App;
