/* eslint-disable no-underscore-dangle */
/**
 * @fileoverview Creates a Redux store that holds the complete state tree of PrecisePK.
 *
 * @module Redux
 * @category Redux
 * @subcategory Store
 */
 import { createStore, applyMiddleware, compose } from 'redux';
 import thunk from 'redux-thunk';
 import { persistStore } from 'redux-persist';
 import RootReducer from './reducers/RootReducer';
 
 /**
  * Sets the initialState of redux to an empty object.
  *
  * @memberof Redux
  * @constant {Object} initialState
  */
 const initialState = {};
 
 /**
  * Creates middleware for Redux that will extend the Store's ability. This includes, but is not limited to writing
  * async logic, writing complex synchronous logic, and mainly write action creators that resemble a function rather than
  * the traditional action.
  * <br/>
  * @see https://github.com/reduxjs/redux-thunk#why-do-i-need-this
  *
  * @memberof Redux
  * @constant {function} middleware
  */
 const middleware = [thunk];
 
 /**
  * Sets up redux-thunk middleware and Redux DevTools for debugging if the application is running in a development
  * environment.
  *
  * @function ReduxMiddlewares
  *
  * @returns {function} A store enhancer that applies the given middleware. The store enhancer signature is
  *      createStore => createStore but the easiest way to apply it is to pass it to createStore() as the last
  *      enhancer argument.
  */
 const ReduxMiddlewares = process.env.REACT_APP_ENVIRONMENT === 'production'
     ? applyMiddleware(...middleware)
     // eslint-disable-next-line max-len
     : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(...middleware))) ||
         compose(applyMiddleware(...middleware));
 
 /**
  * Calls Redux's createStore function that will: Create a Redux Store by taking in a store (RootReducer - function),
  * an initial state (initialState), and an enhancer (ReduxMiddlewares - function).
  * <br/>
  * A "store" is a container that holds your application's global state.
  *
  * @function Store
  *
  * @param {Reducer} RootReducer A reducing function that returns the next state tree, given the current state tree
  *      and an action to handle.
  * @param {Object} initialState Initial state of redux during creation
  * @param {Enhancer} ReduxMiddlewares he store enhancer. You may optionally specify it to enhance the store with
  *      third-party capabilities such as middleware, time travel, persistence, etc.
  *
  * @returns {Store}  An object that holds the complete state of the application. The only way to change its state is by
  *      dispatching actions.
  */
 const Store = createStore(
     RootReducer,
     initialState,
     ReduxMiddlewares,
 );
 
 /**
  * Employs a library called redux-persist which allows the Redux store to save in the local storage of the application.
  * <br/>
  * Currently not in use.
  *
  * @constant {boolean} persistor
  * @deprecated
  */
 export const persistor = persistStore(Store);
 
 export default Store;
 