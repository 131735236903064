/* eslint-disable max-len */
/**
 * @fileoverview
 *     This file contains the Home component, which resides all important components of this application
 */
import React from 'react';
import {
    Card, Container, Col, Row, Badge,
} from 'react-bootstrap';
import ModelInput from './ModelInput';
import PrecisePK from '../media/full_logo.png';

import './Home.css';

const DESCRIPTION = `
    PrecisePK - AKI Predictor is a predictive tool for assessing a patient's risk of developing acute kidney injury at any point during their hospital stay.
    After entering the required information, AKI Predictor will estimate the patient's probability of developing AKI according to the KDIGO criteria. 
`;

/**
 * @param {number} result The percentage result return by backend server, which stored in the redux store.
 *
 * @returns The home component
 */
const Home = () => (
    <div className="home-container-div">
        <Container className="d-flex justify-content-center">
            <Col>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <div className="homeLogo-div">
                            <a href="https://www.precisepk.com/">
                                <img
                                    alt="precisepk-logo"
                                    src={PrecisePK}
                                    style={{ width: '200px', height: '35px' }}
                                />
                            </a>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Card className="home-card">
                            <Card.Title className="home-cardTitle">
                                AKI Predictor
                                <Badge className="home-badge">
                                    Beta
                                </Badge>
                            </Card.Title>
                            <Card.Body className="home-cardBody">
                                <Row>
                                    <span className="modelInput-description">
                                        {DESCRIPTION}
                                    </span>
                                </Row>
                                <Row>
                                    <ModelInput />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Container>
    </div>
);

export default Home;
