/* eslint-disable max-len */
import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';

import './References.css';

const REF_HEADER = 'References and Sources';
const REF_BODY_DESCRIPTION = `
    The model was trained and validated using data from the MIMIC-IV dataset, including approximately 150,000 adult patients admitted to the BIDMC emergency department or ICU. 
    It has not been validated with external data. 
    As the model is still under development, please use PrecisePK - AKI Predictor with precaution to inform clinical decisions.
`;
const REF_BODY_SOURCES = [
    'Johnson, A., Bulgarelli, L., Pollard, T., Horng, S., Celi, L. A., & Mark, R. (2020). MIMIC-IV (version 0.4). PhysioNet. https://doi.org/10.13026/a3wn-hq05.',
    'Kidney Disease: Improving Global Outcomes (KDIGO) Acute Kidney Injury Work Group. KDIGO clinical practice guideline for acute kidney injury. Kidney Int Suppl2012;2:1e-138.',
];

/**
 * @class References handles rendering all sources used in PrecisePK's AKI prediction.
 *
 * @returns {JSX}
 */
const References = () => (
    <Accordion defaultActiveKey={['-1']}>
        <Accordion.Item>
            <AccordionHeader>
                {REF_HEADER}
            </AccordionHeader>
            <AccordionBody>
                <Col>
                    <Row>
                        <Col xs={12}>
                            {REF_BODY_DESCRIPTION}
                        </Col>
                        <Col className="px-1">
                            <ul className="sources-ul">
                                {REF_BODY_SOURCES.map((item) => (
                                    <li key={item}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </AccordionBody>
        </Accordion.Item>
    </Accordion>
);

export default References;
