export const GENDER = {
    name: 'gender',
    label: 'Sex',
    type: 'option',
    options: ['Male', 'Female'],
    X_pos: 0,
    defaultValue: -1,
    span: 3,
};

export const AGE = {
    name: 'age',
    label: 'Age',
    type: 'number',
    X_pos: 1,
    defaultValue: '',
    min: 18,
    max: 110,
    span: 3,
};

export const PLANNED = {
    name: 'planned',
    label: 'Planned Visit',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 2,
    defaultValue: -1,
    span: 3,
};

export const CARDIOVASCULAR = {
    name: 'cardiovascular',
    label: 'Cardiovascular Surgery',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 3,
    defaultValue: -1,
    span: 3,
};

export const THORAX = {
    name: 'thorax',
    label: 'Thorax Surgery',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 4,
    defaultValue: -1,
    span: 3,
};

export const OTHER_SURGERY = {
    name: 'other_surgery',
    label: 'Other Surgery',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 5,
    defaultValue: -1,
    span: 3,
};

export const ICU = {
    name: 'ICU',
    label: 'ICU Admission',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 6,
    defaultValue: -1,
    span: 3,
};

export const BICARBONATE = {
    name: 'bicarbonate',
    label: 'Bicarbonate (mEq/L)',
    type: 'number',
    X_pos: 7,
    defaultValue: '',
    min: 0,
    max: 750,
    span: 3,
};

export const SODIUM = {
    name: 'sodium',
    label: 'Sodium (mEq/L)',
    type: 'number',
    X_pos: 8,
    defaultValue: '',
    min: 0,
    max: 750,
    span: 3,
};

export const CHLORIDE = {
    name: 'chloride',
    label: 'Chloride (mEq/L)',
    type: 'number',
    X_pos: 9,
    defaultValue: '',
    min: 0,
    max: 750,
    span: 3,
};

export const BLOOD_GLUCOSE = {
    name: 'blood_glucose',
    label: 'Blood Glucose (mg/dL)',
    type: 'number',
    X_pos: 10,
    defaultValue: '',
    min: 0,
    max: 750,
    span: 3,
};

export const POTASSIUM = {
    name: 'potassium',
    label: 'Potassium (mEq/L)',
    type: 'number',
    X_pos: 11,
    defaultValue: '',
    min: 0,
    max: 100,
    span: 3,
};

export const WHITE_BLOOD_CELL = {
    name: 'white_blood_cell',
    label: 'White Blood Cell Count (K/μL)',
    type: 'number',
    X_pos: 12,
    defaultValue: '',
    min: 0,
    max: 100,
    span: 6,
};

export const OBESITY = {
    name: 'obesity',
    label: 'Obesity',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 13,
    defaultValue: -1,
    span: 3,
};

export const DIABETES = {
    name: 'diabetes',
    label: 'Diabetes',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 14,
    defaultValue: -1,
    span: 3,
};

export const CHF = {
    name: 'CHF',
    label: 'CHF',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 15,
    defaultValue: -1,
    span: 3,
};

export const FIRST_SCR = {
    name: 'first_scr',
    label: 'Initial SCr (mg/dL)',
    type: 'number',
    X_pos: 16,
    defaultValue: '',
    min: 0,
    max: 20,
    span: 3,
};

export const CONCOMITANT = {
    name: 'concomitant',
    label: 'Concomitant Nephrotoxic Agents',
    type: 'option',
    options: ['No', 'Yes'],
    X_pos: 17,
    defaultValue: -1,
    span: 6,
};

export const MODEL_SELECTION = {
    name: 'model',
    options: ['Logistic Regression', 'Random Forest', 'Dense Neural Network'],
    reducer_options: ['LR', 'RF', 'NN'],
    defaultValue: 2,
    span: 3,
};
